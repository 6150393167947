<template>
    <q-card flat class="row column">
        <q-card-section class="row justify-center q-pb-none">
            <AVLogo
                caption="Pet Reservation System"
                @click="router.push({ name: 'Dashboard' })"
            />
        </q-card-section>
        <q-card-section class="row column justify-center q-pt-none">
            <q-list padding class="rounded-borders">
                <q-item
                    v-ripple
                    clickable
                    class="rounded-borders"
                    :class="{
                        'active-link': route.name === 'Dashboard'
                    }"
                    @click="router.push({ name: 'Dashboard' })"
                >
                    <q-item-section avatar>
                        <q-icon name="home" />
                    </q-item-section>

                    <q-item-section> Dashboard </q-item-section>
                </q-item>

                <q-expansion-item
                    v-model="expansionItems.pet_policies"
                    expand-separator
                    icon="receipt"
                    label="Pet Policies"
                    group="expansion_group"
                    expand-icon="0"
                    @click="handleExpansionItemToggle('pet_policies')"
                >
                    <q-list class="q-pl-lg q-py-sm">
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'PetPoliciesCabinForm'
                            }"
                            @click="router.push({ name: 'PetPoliciesCabin' })"
                        >
                            <q-item-section>Cabin (PETC)</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name ===
                                    'PetPoliciesServiceAnimalForm'
                            }"
                            @click="
                                router.push({
                                    name: 'PetPoliciesServiceAnimal'
                                })
                            "
                        >
                            <q-item-section
                                >Service Animal (SVAN)</q-item-section
                            >
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'PetPoliciesExcessForm'
                            }"
                            @click="
                                router.push({
                                    name: 'PetPoliciesExcessBaggage'
                                })
                            "
                        >
                            <q-item-section
                                >Excess Baggage (AVIH)</q-item-section
                            >
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-expansion-item
                    v-model="expansionItems.pricing"
                    expand-separator
                    icon="attach_money"
                    label="Pricing"
                    expand-icon="0"
                    group="expansion_group"
                    @click="handleExpansionItemToggle('pricing')"
                >
                    <q-list class="q-pl-lg q-py-sm">
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Pricing' &&
                                    route.params.transportType === 'PETC'
                            }"
                            @click="
                                router.push({
                                    name: 'Pricing',
                                    params: { transportType: 'PETC' }
                                })
                            "
                        >
                            <q-item-section>Cabin (PETC) </q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Pricing' &&
                                    route.params.transportType === 'SVAN'
                            }"
                            @click="
                                router.push({
                                    name: 'Pricing',
                                    params: { transportType: 'SVAN' }
                                })
                            "
                        >
                            <q-item-section
                                >Service Animal (SVAN)</q-item-section
                            >
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Pricing' &&
                                    route.params.transportType === 'AVIH'
                            }"
                            @click="
                                router.push({
                                    name: 'Pricing',
                                    params: { transportType: 'AVIH' }
                                })
                            "
                        >
                            <q-item-section
                                >Excess Baggage (AVIH)</q-item-section
                            >
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-expansion-item
                    v-model="expansionItems.inventory"
                    expand-separator
                    icon="grid_3x3"
                    label="Inventory"
                    expand-icon="0"
                    group="expansion_group"
                    @click="handleExpansionItemToggle('inventory')"
                >
                    <q-list class="q-pl-lg q-py-sm">
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Inventory' &&
                                    route.params.transportType === 'PETC'
                            }"
                            @click="
                                router.push({
                                    name: 'Inventory',
                                    params: { transportType: 'PETC' }
                                })
                            "
                        >
                            <q-item-section>Cabin (PETC)</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Inventory' &&
                                    route.params.transportType === 'SVAN'
                            }"
                            @click="
                                router.push({
                                    name: 'Inventory',
                                    params: { transportType: 'SVAN' }
                                })
                            "
                        >
                            <q-item-section
                                >Service Animal (SVAN)</q-item-section
                            >
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link':
                                    route.name === 'Inventory' &&
                                    route.params.transportType === 'AVIH'
                            }"
                            @click="
                                router.push({
                                    name: 'Inventory',
                                    params: { transportType: 'AVIH' }
                                })
                            "
                        >
                            <q-item-section
                                >Excess Baggage (AVIH)</q-item-section
                            >
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-item
                    v-ripple
                    clickable
                    class="rounded-borders"
                    :class="{
                        'active-link': route.name === 'FlightSchedule'
                    }"
                    @click="router.push({ name: 'FlightSchedule' })"
                >
                    <q-item-section avatar>
                        <q-icon name="flight" />
                    </q-item-section>

                    <q-item-section>Flight Schedule</q-item-section>
                </q-item>

                <q-item
                    v-ripple
                    clickable
                    class="rounded-borders"
                    :class="{
                        'active-link': route.name === 'PetPassengers'
                    }"
                    @click="router.push({ name: 'PetPassengers' })"
                >
                    <q-item-section avatar>
                        <q-icon name="pets" />
                    </q-item-section>

                    <q-item-section>Pet Passengers</q-item-section>
                </q-item>

                <q-expansion-item
                    v-model="expansionItems.settings"
                    expand-separator
                    icon="domain"
                    label="Settings"
                    expand-icon="0"
                    group="expansion_group"
                    @click="handleExpansionItemToggle('settings')"
                >
                    <q-list class="q-pl-lg q-py-sm">
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'AirlineSettings'
                            }"
                            @click="
                                router.push({
                                    name: 'AirlineSettings'
                                })
                            "
                        >
                            <q-item-section>Airline</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'SystemSettings'
                            }"
                            @click="
                                router.push({
                                    name: 'SystemSettings'
                                })
                            "
                        >
                            <q-item-section>System</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'Branding'
                            }"
                            @click="
                                router.push({
                                    name: 'Branding'
                                })
                            "
                        >
                            <q-item-section>Branding</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'LegalSettings'
                            }"
                            @click="
                                router.push({
                                    name: 'LegalSettings'
                                })
                            "
                        >
                            <q-item-section>Legal</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'CurrencySettings'
                            }"
                            @click="
                                router.push({
                                    name: 'CurrencySettings'
                                })
                            "
                        >
                            <q-item-section>Currency</q-item-section>
                        </q-item>
                        <q-item
                            v-ripple
                            clickable
                            class="rounded-borders"
                            :class="{
                                'active-link': route.name === 'MembersSettings'
                            }"
                            @click="
                                router.push({
                                    name: 'MembersSettings'
                                })
                            "
                        >
                            <q-item-section>Members</q-item-section>
                        </q-item>
                    </q-list>
                </q-expansion-item>

                <q-item
                    v-ripple
                    clickable
                    class="rounded-borders"
                    :class="{
                        'active-link': route.name === 'UserAccount'
                    }"
                    @click="router.push({ name: 'UserAccount' })"
                >
                    <q-item-section avatar>
                        <q-icon name="settings" />
                    </q-item-section>

                    <q-item-section>Account</q-item-section>
                </q-item>
            </q-list>
        </q-card-section>
        <q-card-section class="q-mt-auto row justify-between cursor-pointer">
            <p class="q-my-auto" @click="logout">Logout</p>
            <AVButton flat @click="logout">
                <AVIcon name="logout" color="red" size="sm" />
            </AVButton>
        </q-card-section>
    </q-card>
</template>

<script setup>
import { ref, watch } from "vue"
import { useAuthStore } from "../../store/auth"
import { useRouter, useRoute } from "vue-router"

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()

const expansionItems = ref({
    pricing: false,
    pet_policies: false,
    inventory: false,
    settings: false
})

const handleExpansionItemToggle = (item) => {
    const parentRoute = route.fullPath.split("/")[1]

    if (item === "pricing" && !expansionItems.value.pricing) {
        expansionItems.value.pricing = true
    } else if (item === "pet_policies" && !expansionItems.value.pet_policies) {
        expansionItems.value.pet_policies = true
    } else if (item === "inventory" && !expansionItems.value.inventory) {
        expansionItems.value.inventory = true
    } else if (item === "settings" && !expansionItems.value.settings) {
        expansionItems.value.settings = true
    }

    if (parentRoute !== "pricing" && item === "pricing") {
        router.push({ name: "Pricing", params: { transportType: "PETC" } })
    } else if (parentRoute !== "pet-policies" && item === "pet_policies") {
        router.push({ name: "PetPoliciesCabin" })
    } else if (parentRoute !== "inventory" && item === "inventory") {
        router.push({ name: "Inventory", params: { transportType: "PETC" } })
    } else if (parentRoute !== "settings" && item === "settings") {
        router.push({ name: "AirlineSettings" })
    }
}

watch(
    () => route.name,
    (val) => {
        if (
            ![
                "Inventory",
                "Pricing",
                "AirlineSettings",
                "SystemSettings",
                "MembersSettings",
                "PetPoliciesCabinForm",
                "PetPoliciesServiceAnimal",
                "PetPoliciesExcessBaggage",
                "PetPoliciesExcessForm",
                "PetPoliciesServiceAnimalForm",
                "LegalSettings",
                "CurrencySettings",
                "Branding"
            ].includes(val)
        ) {
            Object.keys(expansionItems.value).forEach((key) => {
                expansionItems.value[key] = false
            })
        }
    }
)

const logout = async () => {
    await authStore.logout()
    router.push({ name: "Login" })
}
</script>
