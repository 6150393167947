import { ref, computed, defineEmits } from "vue"
import { useRouter } from "vue-router"
import { useQuasar } from "quasar"

export default function useForm(props) {
    const router = useRouter()
    const $q = useQuasar()

    const processing = ref(false)
    const formRef = ref(null)

    const handleFormError = (error, form) => {
        const { response = {} } = error
        const { data = {} } = response
        const { code = "", message = "" } = data

        if (message) {
            $q.notify({
                message: message,
                position: "top-right",
                color: "red"
            })
        }

        switch (code) {
            case 1411:
                router.push({
                    name: "ChangePassword",
                    params: { username: form.username }
                })
        }
    }

    const languages = [{ value: "English", label: "English" }]

    const currencies = [
        { value: "USD", symbol: "$", label: "USD" },
        { value: "EUR", symbol: "€", label: "EUR" }
    ]

    const metrics = [
        { value: "Metric", label: "Metric" },
        { value: "Imperial", label: "Imperial" }
    ]

    const temperature = [
        { value: "Celsius", label: "Celsius" },
        { value: "Fahrenheit", label: "Fahrenheit" }
    ]

    const roles = [
        { value: "MEMBER", label: "Member" },
        { value: "ADMIN", label: "Admin" }
    ]

    const booleans = [
        { value: true, label: "True" },
        { value: false, label: "False" }
    ]

    const emit = defineEmits(["update:modelValue"])

    const form = computed({
        get() {
            return props.modelValue
        },
        set(val) {
            emit("update:modelValue", val)
        }
    })


    return {
        emit,
        form,
        handleFormError,
        processing,
        languages,
        currencies,
        metrics,
        temperature,
        formRef,
        roles,
        booleans
    }
}
