<template>
    <AVRow row class="q-mb-md">
        <a
            :href="props.privacyPolicy.link"
            target="_blank"
            class="q-my-auto text-grey"
            >{{ props.privacyPolicy.text }}</a
        >
        <a
            :href="props.termsOfService.link"
            target="_blank"
            class="text-grey q-my-auto"
            >{{ props.termsOfService.text }}</a
        >
        <p class="q-my-auto">.</p>
        <div class="flex">
            <p class="q-my-auto">Powered By</p>
            <q-img
                :src="logo"
                class="q-ml-sm"
                style="
                     {
                        object-fit: contain;
                        width: 100px;
                    }
                "
            />
        </div>
    </AVRow>
</template>

<script setup>
import { defineProps } from "vue"
import logo from "/src/assets/pawtrip_with_paw.svg"
import useResponsiveness from "/src/composables/useResponsiveness.js"

const props = defineProps({
    privacyPolicy: {
        type: Object,
        default: () => ({
            link: "https://www.pawtrip.com/privacy-policy",
            text: "Privacy Policy"
        })
    },
    termsOfService: {
        type: Object,
        default: () => ({
            link: "https://www.pawtrip.com/terms-of-service",
            text: "Terms of Service"
        })
    }
})

const { isMobileView, isNotMobileView } = useResponsiveness()
</script>

<style lang="scss" scoped>
a {
    text-decoration: underline;
}
.footer-powered {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: fit-content;
}
</style>
