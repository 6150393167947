import { createRouter, createWebHistory } from "vue-router"
import SingleViewLayout from "@/layouts/SingleViewLayout.vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "./store/auth"
import jwt_decode from "jwt-decode"
import MainLayout from "@/layouts/MainLayout.vue"

const routes = [
  {
    path: "/",
    component: MainLayout,
    redirect: { name: "Dashboard" },
    props: () => ({ title: "Dashboard" }),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/dashboard/Index.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/forms",
    component: MainLayout,
    props: () => ({ title: "Cargo Request Form" }),
    redirect: { name: "AgentInfoForm" },
    children: [
      {
        path: "cargo",
        name: "CargoForm",
        component: () => import("@/views/forms/cargo/Index.vue"),
        children: [
          {
            path: "agent-info",
            name: "AgentInfoForm",
            meta: {
              title: "Agent Info"
            },
            component: () =>
              import("@/views/forms/cargo/AgentInfo.vue")
          },
          {
            path: "pet-info",
            name: "PetInfoForm",
            meta: {
              title: "Pet Info"
            },
            component: () =>
              import("@/views/forms/cargo/PetInfo.vue")
          },
          {
            path: "travel-details",
            name: "TravelDetailsForm",
            meta: {
              title: "Travel Details"
            },
            component: () =>
              import("@/views/forms/cargo/TravelDetails.vue")
          },
          {
            path: "health-certificates",
            name: "HealthCertificatesForm",
            meta: {
              title: "Health Certifications"
            },
            component: () =>
              import("@/views/forms/cargo/HealthCertificates.vue")
          }
        ]
      }
    ]
  },
  {
    path: "/pet-policies",
    component: MainLayout,
    props: (route) => {
      const { name } = route
      let title = ""
      switch (name) {
        case "PetPoliciesCabin":
          title = "Pet Policies: Cabin (PETC)"
          break
        case "PetPoliciesServiceAnimal":
          title = "Pet Policies: Service Animal (SVAN) "
          break
        case "PetPoliciesExcessBaggage":
          title = "Pet Policies: Excess Baggage (AVIH)"
          break
      }

      return { title }
    },
    redirect: { name: "PetPoliciesCabin" },
    children: [
      {
        path: "cabin",
        name: "PetPoliciesCabin",
        props: () => ({ transportType: "PETC" }),
        component: () => import("@/views/pet-policies/cabin/Index.vue"),
        redirect: {
          name: "PetPoliciesCabinForm",
          params: { form: "passenger-requirements" }
        },
        children: [
          {
            path: ":form",
            name: "PetPoliciesCabinForm",
            component: () =>
              import("@/views/pet-policies/cabin/Forms.vue")
          }
        ]
      },
      {
        path: "service-animal",
        name: "PetPoliciesServiceAnimal",
        props: () => ({ transportType: "SVAN" }),
        component: () =>
          import("@/views/pet-policies/service-animal/Index.vue"),
        redirect: {
          name: "PetPoliciesServiceAnimalForm",
          params: { form: "passenger-requirements" }
        },
        children: [
          {
            path: ":form",
            name: "PetPoliciesServiceAnimalForm",
            component: () =>
              import("@/views/pet-policies/service-animal/Forms.vue")
          }
        ]
      },
      {
        path: "excess-baggage",
        name: "PetPoliciesExcessBaggage",
        props: () => ({ transportType: "AVIH" }),
        component: () =>
          import("@/views/pet-policies/excess-baggage/Index.vue"),
        redirect: {
          name: "PetPoliciesExcessForm",
          params: { form: "passenger-requirements" }
        },
        children: [
          {
            path: ":form",
            name: "PetPoliciesExcessForm",
            component: () =>
              import("@/views/pet-policies/excess-baggage/Forms.vue")
          }
        ]
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/flight-schedule",
    component: MainLayout,
    redirect: { name: "FlightSchedule" },
    props: () => ({ title: "Flight Schedule" }),
    children: [
      {
        path: "",
        name: "FlightSchedule",
        component: () =>
          import("@/views/flight-schedule/FlightSchedule.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/inventory",
    component: MainLayout,
    props: (route) => {
      const { name, params } = route
      const { transportType } = params
      let page = ""
      switch (transportType) {
        case "PETC":
          page = "Cabin"
          break
        case "SVAN":
          page = "Service Animal"
          break
        case "AVIH":
          page = "Excess Baggage"
          break
      }

      return { title: `${name}: ${page} (${transportType})`, transportType: transportType }
    },
    redirect: { name: "Inventory" },
    children: [
      {
        path: ":transportType",
        name: "Inventory",
        component: () => import("@/views/inventory/Index.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pricing",
    component: MainLayout,
    props: (route) => {
      const { name, params } = route
      const { transportType } = params
      let page = ""
      switch (transportType) {
        case "PETC":
          page = "Cabin"
          break
        case "SVAN":
          page = "Service Animal"
          break
        case "AVIH":
          page = "Excess Baggage"
          break
      }

      return { title: `${name}: ${page} (${transportType})`, transportType: transportType }
    },
    children: [
      {
        path: ":transportType",
        name: "Pricing",
        component: () => import("@/views/pricing/Index.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    component: MainLayout,
    redirect: { name: "AirlineSettings" },
    props: (route) => {
      const { name } = route

      return { title: `${name.replace("Settings", "")}` }
    },
    children: [
      {
        path: "airline",
        name: "AirlineSettings",
        component: () => import("@/views/settings/Index.vue")
      },
      {
        path: "system",
        name: "SystemSettings",
        component: () => import("@/views/settings/Index.vue")
      },
      {
        path: "branding",
        name: "Branding",
        component: () => import("@/views/settings/Index.vue")
      },
      {
        path: "legal",
        name: "LegalSettings",
        component: () => import("@/views/settings/Index.vue")
      },
      {
        path: "member",
        name: "MembersSettings",
        component: () => import("@/views/settings/Member.vue")
      },
      {
        path: "currency",
        name: "CurrencySettings",
        component: () => import("@/views/settings/Index.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/account",
    component: MainLayout,
    redirect: { name: "UserAccount" },
    props: () => ({ title: "Account" }),
    children: [
      {
        path: "",
        name: "UserAccount",
        component: () => import("@/views/account/UserAccount.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/pet-passengers",
    component: MainLayout,
    redirect: { name: "PetPassengers" },
    props: () => ({ title: "Pet Passengers" }),
    children: [
      {
        path: "",
        name: "PetPassengers",
        component: () =>
          import("@/views/pet-passengers/PetPassengers.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    component: SingleViewLayout,
    redirect: { name: "Login" },
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/views/auth/Login.vue")
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/change-password/",
    component: SingleViewLayout,
    redirect: { name: "ChangePassword" },
    children: [
      {
        path: "",
        name: "ChangePassword",
        component: () => import("@/views/auth/ChangePassword.vue")
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/register",
    component: SingleViewLayout,
    redirect: { name: "Register" },
    children: [
      {
        path: "",
        name: "Register",
        component: () => import("@/views/auth/Register.vue")
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/accept-invitation",
    component: SingleViewLayout,
    redirect: { name: "Register" },
    props: route => ({ token: route.params.token, email: route.query.email }),
    children: [
      {
        path: "",
        name: "Register",
        component: () => import("@/views/auth/Register.vue")
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/verify",
    component: SingleViewLayout,
    redirect: { name: "Verify" },
    children: [
      {
        path: "",
        name: "Verify",
        component: () => import("@/views/auth/Verify.vue")
      }
    ],
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/resend-code",
    component: SingleViewLayout,
    redirect: { name: "ResendVerificationCode" },
    children: [
      {
        path: "",
        name: "ResendVerificationCode",
        component: () =>
          import("@/views/auth/ResendVerificationCode.vue")
      }
    ],
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token === null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let decoded = jwt_decode(token)
      if (decoded.exp < Date.now() / 1000) {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
