/**
 * @file This file describes PRS's API client for the Avvinue backend, Tails
 *
 * @see https://gitlab.com/avvinue-pet-travel/tails
 */
import axios from "axios"
import { Notify } from "quasar"
import router from "../router.js"

const api = axios.create({
    baseURL: import.meta.env.VITE_TAILS_API,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config
    },
    (error) => Promise.reject(error)
)

api.interceptors.response.use(
    (response) => {
        const { data = {} } = response
        const { message = "" } = data

        if (message) {
            // Notify.create({
            //     message,
            //     position: "top-right",
            //     type: "positive",
            //     iconColor: "white"
            // })
        }

        return response
    },
    (error) => {
        if (error?.response?.data?.message) {
            // Notify.create({
            //     message: error?.response?.data?.message,
            //     position: "top-right",
            //     type: "negative",
            //     iconColor: "white"
            // })
        }

        if (error.response.status === 401) {
            router.push({ name: "Login" })
        }

        const { code = "" } = error.response.data

        if (code === "UserNotConfirmedException") {
            router.push({ name: "ResendVerificationCode" })
        }

        return Promise.reject(error)
    }
)

export { api }
