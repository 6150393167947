<template>
    <q-layout view="lHh lpR lFf">
        <q-header
            class="text-grey-9 bg-white"
            bordered
            height-hint="98"
            style="height: 56px; margin-bottom: 0"
        >
            <Header @open-drawer="leftDrawerOpen = true" />
        </q-header>

        <q-drawer
            v-model="leftDrawerOpen"
            class="window-height"
            show-if-above
            side="left"
            bordered
            :width="250"
        >
            <Sidebar />
        </q-drawer>

        <q-page-container>
            <q-page padding>
                <p class="text-h5 text-weight-bold">{{ title }}</p>
                <router-view />
            </q-page>
        </q-page-container>

        <q-footer class="bg-white text-grey q-px-md">
            <AVFooter />
        </q-footer>
    </q-layout>
</template>

<script setup>
import { ref, defineProps } from "vue"
import Sidebar from "../components/single-use/Sidebar.vue"
import Header from "../components/single-use/Header.vue"
import AVFooter from "../components/atoms/AVFooter.vue"

defineProps({
    title: {
        type: String,
        default: ""
    }
})

const leftDrawerOpen = ref(false)
</script>
