import { createApp } from "vue"
import { createPinia } from "pinia"
import { createI18n } from "vue-i18n"
import App from "./App.vue"
import VueRouter from "@/router"
import { Quasar, Notify, Dialog } from "quasar"
import messages from "@/plugins/i18n"
import * as Sentry from "@sentry/vue";

import {
  AVButton,
  AVCheckbox,
  AVDate,
  AVIcon,
  AVRadio,
  AVInput,
  AVRow,
  AVSelect,
  AVTable,
  AVToggle,
  AVWrapper,
  AVFooter,
  AVLogo,
  AVDrawer,
  AVDatePicker,
  AVColorPicker,
  AVModal,
  AVBanner,
  AVRichText,
  AVBreadCrumbs
} from "@avvinue-pet-travel/clowder"

import "@quasar/extras/material-icons/material-icons.css"
import "quasar/src/css/index.sass"
import "./style/index.sass"
import "../node_modules/@avvinue-pet-travel/clowder/dist/style.css"

const app = createApp(App)

// Register Global Components
app.component("AVBreadCrumbs", AVBreadCrumbs)
app.component("AVButton", AVButton)
app.component("AVCheckbox", AVCheckbox)
app.component("AVDate", AVDate)
app.component("AVIcon", AVIcon)
app.component("AVInput", AVInput)
app.component("AVRadio", AVRadio)
app.component("AVRichText", AVRichText)
app.component("AVRow", AVRow)
app.component("AVSelect", AVSelect)
app.component("AVTable", AVTable)
app.component("AVToggle", AVToggle)
app.component("AVWrapper", AVWrapper)
app.component("AVFooter", AVFooter)
app.component("AVLogo", AVLogo)
app.component("AVDrawer", AVDrawer)
app.component("AVDatePicker", AVDatePicker)
app.component("AVColorPicker", AVColorPicker)
app.component("AVModal", AVModal)
app.component("AVBanner", AVBanner)

app.use(Quasar, {
  plugins: { Notify, Dialog },
  config: {}
})
  .use(createPinia())
  .use(VueRouter)
  .use(
    createI18n({
      locale: "en",
      messages
    })
  )

Sentry.init({
  app,
  dsn: "https://c14f95f5aa57ec183b2fd798f71324a1@o4507289868042240.ingest.us.sentry.io/4507973287280640",
  integrations: [
    Sentry.browserTracingIntegration({ VueRouter }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/pawtrip\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



app.mount("#app")
