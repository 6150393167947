<template>
    <AVRow class="q-ma-none q-px-lg q-py-sm" flex row justify="space-between">
        <AVRow row justify="center">
            <AVButton
                v-if="['sm', 'xs'].includes(breakpoint)"
                flat
                icon="menu"
                @click="emit('open-drawer')"
            />
            <!-- <q-avatar rounded size="40px" class="q-my-auto">
                <q-img :src="logo" />
            </q-avatar> -->
            <AVRow column justify="center">
                <p class="q-my-none text-weight-bold text-body2">
                    {{ airline_name }}
                </p>
            </AVRow>
        </AVRow>
        <AVRow row justify="flex-end">
            <q-avatar
                size="30px"
                color="grey-4"
                text-color="white"
                class="q-my-auto cursor-pointer"
                @click="router.push({ name: 'UserAccount' })"
            >
                <AVIcon name="person" size="1.3rem" color="grey" />
            </q-avatar>
            <p class="text-caption q-my-auto text-weight-bold">
                {{ fullname }}
            </p>
        </AVRow>
    </AVRow>
</template>

<script setup>
import { defineEmits } from "vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "@/store/auth.js"
import { useRouter } from "vue-router"
import useResponsiveness from "../../composables/useResponsiveness"

const router = useRouter()
const authStore = useAuthStore()

const emit = defineEmits(["open-drawer"])

const { logo, airline_name, fullname } = storeToRefs(authStore)
const { breakpoint } = useResponsiveness()
</script>
