import { defineStore } from "pinia"
import { Dialog } from "quasar"
import useForm from "@/composables/useForm"
import { api } from "@/plugins/api.js"

export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            user: {
                role: null,
            },
            account: {},
            org_users: [],
            invitees: [],
            token: null
        }
    },
    getters: {
        isMember: (state) => state?.user?.airline?.role[0] == 'MEMBER',
        logo: (state) => state?.user?.airline?.logo,
        account_id: (state) => state?.account?.prs_account_id,
        username: (state) => state?.user?.username,
        fullname: (state) => state?.user?.name,
        jobTitle: (state) => state?.user?.jobTitle,
        iataCode: (state) => state?.user?.airline?.iataCode,
        airline_name: (state) => state?.user?.airline?.airline_name,
        airline_id: (state) => state?.user?.airline?.id,
        authenticated: (state) => !!state.token,
        currencySymbol: (state) => {
            const { currencies } = useForm()
            const value = state?.user?.airline?.currency

            return currencies.find((c) => c.value === value)?.symbol
        },
        measurementSystem: (state) => {
            const obj = {}
            const { measurement = "" } = state?.user?.airline ?? {}

            if (measurement) {
                if (measurement === 1) {
                    obj["length"] = "cm"
                    obj["weight"] = "kg"
                    obj["temperature"] = "°C"
                } else {
                    obj["length"] = "in"
                    obj["weight"] = "lb"
                    obj["temperature"] = "°F"
                }
            }

            return obj
        }
    },
    actions: {
        setUser({ user, token }) {
            this.user = { ...user, role: user.airline.role }
            this.token = token
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user))
        },
        async getUser() {
            const { data } = await api.get("/user")

            this.setUser(data)
        },
        async getOrgUsers() {
            const { data } = await api.get(`/prs/airline/${this.account_id}/team-members`)
            const { users, invites } = data
            this.org_users = users
            this.invitees = invites
        },
        deleteInvitee(id) {
            Dialog.create({
                title: "Are you sure?",
                message: "This action cannot be undone",
                cancel: true
            }).onOk(async () => {
                await api.post("/delete-user", { invite_id: id })
                this.invitees = this.invitees.filter(
                    (invitee) => invitee.id !== id
                )
            })
        },
        async saveAccount() {
            try {
                await api.post("/account", this.account)
            } catch (e) {
                this.account = e.response.data.original
            }
        },
        async login(form) {
            const response = await api.post("/auth/login", form)
            this.setUser(response.data)
        },
        async logout() {
            // await api.post("/logout", {})
            this.token = null
            localStorage.removeItem('token');
        },
        async changePassword(form) {
            const { data } = await api.post("/set-new-password", {
                password: form.password,
                passwordConfirmation: form.passwordConfirmation,
                userAttributes: {
                    name: form.name
                }
            })

            this.setUser(data)
        },
        async inviteUser(user) {
            await api.post("/invite-user", {
                prsAccountId: this.account_id,
                senderName: this.username,
                ...user
            })
        },
        async register(payload) {
            await api.post("/auth/admin/register", payload)
        }
    }
})
